import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

const useStyles = makeStyles((theme: Theme) => {
  return {
    root: {},
    tabContainer: {
      display: 'flex !important',
      flexDirection: 'column',
      width: '100%',
      textAlign: 'left',
      padding: theme.spacing(1),
    },
    title: {
      color: theme.palette.grey[400],
      paddingRight: theme.spacing(1),
    },
    count: {
      paddingRight: theme.spacing(1),
      fontWeight: 700,
      fontSize: '16px',
    },
    countDefault: {
      paddingRight: theme.spacing(1),
      fontWeight: 700,
      fontSize: '16px',
      color: theme.palette.grey[900],
    },
    countInActive: {
      paddingRight: theme.spacing(1),
      fontWeight: 700,
      fontSize: '16px',
      color: theme.palette.primary.light,
    },
  };
});

export default useStyles;
