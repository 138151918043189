import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

const useStyles = makeStyles((theme: Theme) => {
  return {
    root: {},
    content: {
      width: '520px',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
    textarea: {
      resize: 'none',
      padding: theme.spacing(2),
      width: '100%',
      lineHeight: theme.spacing(3),
      borderRadius: theme.spacing(1),
      borderColor: '#AEAEAE',
      fontFamily: 'inherit',
      color: theme.palette.text.secondary,
    },
    footer: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginTop: theme.spacing(2),
      columnGap: '10px',
    },
  };
});

export default useStyles;
