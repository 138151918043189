import { Theme } from '@mui/material';
// eslint-disable-next-line
const ButtonStyles = (theme: Theme): any => ({
  root: {
    height: '24px',
    textTransform: 'none',
    // padding: '0 8px',
    color: '#2961FD',
    backgroundColor: '#D7E2FF',
    borderRadius: '4px',
    '&:hover': {
      backgroundColor: theme.palette.grey[100],
      boxShadow: 'none',
    },
  },
});

export { ButtonStyles };
