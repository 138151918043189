import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

const useStyles = makeStyles((theme: Theme) => {
  return {
    root: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      margin: 0,
    },
    meta: {
      columnGap: theme.spacing(1),
      '& strong': {
        marginLeft: theme.spacing(1),
        color: theme.palette.grey[700],
      },
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
      },
    },
    leftColumn: {
      flex: '0 1 auto',
      [theme.breakpoints.down('md')]: {
        flex: '0 1 100%',
        display: 'flex',
        flexDirection: 'column',
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
      },
    },
    rightColumn: {
      flex: 1,
      display: 'flex',
      justifyContent: 'flex-end',
      flexDirection: 'row',
      columnGap: '10px',
      [theme.breakpoints.down('sm')]: {
        flex: 'unset',
        flexDirection: 'column',
        rowGap: theme.spacing(1),
      },
    },
  };
});

export default useStyles;
