import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

const useStyles = makeStyles((theme: Theme) => {
  return {
    root: {},
    stackView: {
      backgroundColor: theme.palette.grey[50],
    },
    sectionItem: {
      '& .MuiBox-root': {
        backgroundColor: theme.palette.grey[100],
        fontSize: '11px',
        fontWeight: 600,
        color: theme.palette.grey[700],
      },
    },
    personnelItem: {
      fontSize: '13px',
      color: theme.palette.grey[900],
      '& .MuiCheckbox-root': {
        marginRight: theme.spacing(1),
        padding: 0,
        paddingLeft: theme.spacing(1),
      },
      '& .MuiBox-root': {
        display: 'flex',
        alignItems: 'center',
        height: '28px',
      },
      '& .MuiBox-root:not(:first-child)': {
        flex: '0 1 20%',
        justifyContent: 'center',
        fontWeight: 600,
      },
      '& .MuiBox-root:first-child': {
        flex: '0 1 100%',
      },
    },
  };
});

export default useStyles;
