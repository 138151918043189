import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

const useStyles = makeStyles((theme: Theme) => {
  return {
    root: {
      minWidth: '266px',
      [theme.breakpoints.down('md')]: {
        width: '220px',
      },
    },
    location: {
      display: 'flex',
      justifyContent: 'flex-start',
      columnGap: '7px',
    },
    flexBoxes: {
      display: 'flex',
      justifyContent: 'space-between',
      columnGap: '7px',
    },
    greyBox: {
      flex: 1,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: theme.spacing(1),
      borderRadius: '5px',
      backgroundColor: theme.palette.grey[100],
      '& p': {
        color: theme.palette.grey[800],
      },
      [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
      },
    },
  };
});

export default useStyles;
