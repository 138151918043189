import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

const useStyles = makeStyles((theme: Theme) => {
  return {
    root: {
      flex: 1,
      maxWidth: 180,
      width: 180,
      padding: theme.spacing(1),
      borderRadius: '5px',
      backgroundColor: theme.palette.grey[50],
      [theme.breakpoints.down('md')]: {
        width: 120,
      },
      [theme.breakpoints.down('sm')]: {
        padding: `0 ${theme.spacing(1)}`,
        maxWidth: 'unset',
        width: 100,
        height: 42,
      },
    },
    title: {
      color: theme.palette.grey[900],
    },
    meta: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
  };
});

export default useStyles;
